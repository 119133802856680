<script setup lang="ts">
import "@formkit/themes/genesis";
import "@formkit/pro/genesis";
const route = useRoute();

useHead({
  link: () => {
    if (route.path.startsWith("/u/") || route.path.startsWith("/lp/"))
      return [];
    return [{ rel: "icon", href: "/favicon.svg" }];
  },
});
</script>
<template>
  <div style="z-index: 100000">
    <NuxtLoadingIndicator />
  </div>

  <div>
    <NuxtLayout>
      <NuxtPage></NuxtPage>
    </NuxtLayout>
    <AppAlerts />
  </div>
</template>
<style>
:root {
  --fk-font-family-label: "Nexa", sans-serif;
  --fk-font-family-input: "Nexa", sans-serif;
}
</style>
